<template>
  <div class="invoice-wrapper">

    <div class="row">

      <div class="col-xl-1 col-md-2">
        <b-button class="shadow-sm font-weight-bold p-1 align-self-baseline float-left bg-white"
                  variant="white"
                  @click="$router.back()">
          <feather-icon class="txt-green" icon="ChevronLeftIcon" size="20"/>
        </b-button>
      </div>

      <div class="col-xl-9 col-md-7">
        <div class="card invoice-preview-card">
          <div class="col-md-1 logo-wrapper">
            <p class="in-ribbon">{{invoiceDetails.state.toUpperCase()}}</p>
          </div>
          <div class="card-body invoice-padding pb-0">
            <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 align-items-center"
            >
              <div class="text-center text-md-left">
                <h2 class="txt-black font-weight-bolder mb-2">Invoice #{{invoiceDetails.id}}</h2>
                <p class="card-text mb-25">
                  Issue Date: <strong>{{invoiceDetails.issue_date}}</strong>
                </p>
                <p class="card-text mb-0">
                  Due Date: <strong>{{ invoiceDetails.due_date }}</strong>
                </p>
              </div>

              <div class="mt-md-0 mt-2">
                <h5 class="txt-black font-weight-bolder">{{invoiceDetails.user.first_name +' '+ invoiceDetails.user.last_name}}</h5>
                <p>
                  <a href=""
                  >{{invoiceDetails.user.email}}</a
                  >
                </p>
              </div>
            </div>
          </div>
          <hr class="invoice-spacing"/>
          <div class="card-body invoice-padding pt-0">
            <div class="row invoice-spacing py-2">
              <div class="col-xl-6 col-12 text-center text-md-left">
                <h4 class="mb-2 txt-black font-weight-bolder">Invoice To:</h4>
                <p class="txt-black font-weight-bolder">{{invoiceDetails.practice.name}}</p>
                <p>
                  {{invoiceDetails.practice.address}}, {{invoiceDetails.practice.city}}, {{invoiceDetails.practice.postal_code}}<br/>
                  {{invoiceDetails.practice.phone_number}}<br/>
                  <a href=""
                  >{{invoiceDetails.practice.email}}</a
                  >
                </p>
              </div>
              <div
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end col-xl-6 col-12"
              >
                <div class="invoice-price">
                  <h2 class="txt-price txt-black font-weight-bolder mb-2">Balance Due: £{{invoiceDetails.total_invoice_cost}}</h2>

                  <div class="table-responsive tbl-nl">

                    <table class="table b-table">
                      <tbody>
                      <tr>
                        <td class="pr-1">Bank name:</td>
                        <td>
                          <span class="font-weight-bold">{{invoiceDetails.bank_name}}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">IBAN:</td>
                        <td>{{invoiceDetails.bank_account_number}}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Sort code:</td>
                        <td>{{invoiceDetails.bank_sort_code}}</td>
                      </tr>

                      </tbody>
                    </table>

                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive tbl-nl">
            <table
                aria-busy="false"
                aria-colcount="4"
                class="table b-table tbl-invoice"
                role="table"

            >
              <thead class="" role="rowgroup">

              <tr class="" role="row">
                <th aria-colindex="1" class="" role="columnheader" scope="col">
                  <div>Shift Date</div>
                </th>
                <th aria-colindex="2" class="" role="columnheader" scope="col">
                  <div>Hourly Rate</div>
                </th>
                <th aria-colindex="3" class="" role="columnheader" scope="col">
                  <div>HOURS</div>
                </th>
                <th aria-colindex="4" class="" role="columnheader" scope="col">
                  <div>PRICE</div>
                </th>
              </tr>
              </thead>
              <tbody role="rowgroup">

              <tr role="row" v-for="shift in invoiceDetails.invoice_shift">
                <td aria-colindex="1" class="" role="cell">
                  <p class="card-text font-weight-bold mb-25">
                    {{momentFormat(shift.shift.start,'ddd DD-MM-YYYY')}}
                  </p>
                </td>
                <td aria-colindex="2" class="" role="cell">£{{shift.shift.locum_rate}}.00</td>
                <td aria-colindex="2" class="" role="cell">{{ timeDifferentHours(shift.shift.start, shift.shift.end)}}</td>
                <td aria-colindex="4" class="" role="cell">£{{totalShiftCost(shift.shift.start, shift.shift.end,shift.shift.locum_rate )}}</td>
              </tr>

              <tr role="row">
                <td aria-colindex="1" class="" role="cell">&nbsp;</td>
                <td aria-colindex="2" class="" role="cell">&nbsp;</td>
                <td aria-colindex="2" class="py-2" role="cell">Total:</td>
                <td aria-colindex="4" class="font-weight-bolder" role="cell">£{{invoiceDetails.total_invoice_cost}}</td>
              </tr>
              </tbody>
            </table>
          </div>


        </div>
      </div>

      <div class="col-xl-2 col-md-3">

        <div class="d-flex justify-content-end">

          <b-button
              class="shadow-sm font-weight-bold p-1 align-self-baseline float-left bg-white"
              style="margin-right: 4px;"
              variant="white">
            <feather-icon class="txt-green" icon="DownloadIcon" size="20"/>
          </b-button>

          <b-button
              class="shadow-sm font-weight-bold p-1 align-self-baseline float-left bg-white"
              style="margin-right: 4px;"
              variant="white">
            <feather-icon class="txt-green" icon="PrinterIcon" size="20"/>
          </b-button>

          <b-button
              class="shadow-sm font-weight-bold p-1 align-self-baseline float-left bg-white"
              style="margin-right: 4px;"
              variant="white">
            <feather-icon class="txt-green" icon="TrashIcon" size="20"/>
          </b-button>

        </div>


      </div>

    </div>


  </div>
</template>

<script>
import {BButton} from 'bootstrap-vue'
import invoice from "@/apis/modules/invoice";
import MomentMixin from '@/mixins/MomentMixin'
import {mapGetters} from "vuex";



export default {
  name:'view-invoice',
  components: {
    BButton,
  },
  mixins: [MomentMixin],
  data(){
    return{
      invoiceDetails:''

    }
  },
  methods:{
    //get Invoice details
    async invoice(){
      try {
        this.invoiceDetails = (await invoice.getInvoiceDetails(this.$route.params.id)).data

      }catch (error){
        this.convertAndNotifyError(error)
      }
    }
  },
  computed:{
    ...mapGetters(['currentUser']),

  },
  async mounted() {

    await this.invoice()

  }
}
</script>
